import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Image from '../components/Image';
import LogoSection from '../components/LogoSection';
import FeaturedCustomer from '../components/FeaturedCustomer';
import SEO from '../components/SEO';
import CustomerHeroTestimonials from '../components/CustomerHeroTestimonials';

export default (props) => {
  const { seo } = props.data.wpPage;
  const { customerHero, customerLogos, featuredCustomers, moreCustomers, testimonial } = props.data.wpPage.customerListing;

  return (
    <Layout>
      <SEO seo={seo} />
      <section className="hero pad-tb--xs">
        <div className="container">
          <div className="flex flex-wrap -ml-12 xl:-ml-24">
            <div className="w-full pl-12 xl:pl-24 lg:w-7/12 flex flex-col justify-center">
              <div className="text-center text-card lg:text-left lg:max-w-sm xl:max-w-none">
                <h1 className="text-card__heading text-58px">{customerHero.textCard.heading}</h1>
                <div className="">
                  <div className="mx-auto mb-48 lg:max-w-sm text-card__content md:ml-0" dangerouslySetInnerHTML={{ __html: customerHero.textCard.content }} />
                </div>
                {customerHero.textCard.button.title.length && (
                  <Link className="text-link text-link--black" to={customerHero.textCard.button.url}>
                    {customerHero.textCard.button.title}
                  </Link>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full pl-12 mt-16 lg:w-5/12 lg:mt-0">
              <CustomerHeroTestimonials {...customerHero?.testimonials[0]} className={`mb-5 -ml-6 sm:-ml-18`} logoClass={`h-5`} />
              <CustomerHeroTestimonials {...customerHero?.testimonials[1]} className={`mb-5 -mr-6 sm:-mr-18`} logoClass={`h-5 `} />
              <CustomerHeroTestimonials {...customerHero?.testimonials[2]} className={`-ml-6 sm:-ml-18`} logoClass={`h-5`} />
            </div>
          </div>
        </div>
      </section>

      <LogoSection data={customerLogos} cols={4} />

      <section className="featured-customers md:-my-8">
        {featuredCustomers.map((rs, i) => (
          <div key={`featured-${i}`}>
            <FeaturedCustomer customer={rs} reverse={i % 2 !== 0} showStats={true} />
          </div>
        ))}
      </section>

      {moreCustomers && moreCustomers.length > 0 && (
        <section className="mt-40 all-customers mb-28">
          <div className="container">
            <h3 className="mb-16 text-center text-42px">More Stories</h3>
            <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-3">
              {moreCustomers.map((rs, i) => (
                <Link to={rs.link} key={`all-customers-${i}`} className="transition-opacity duration-150 hover:opacity-90">
                  <div className="o-ratio o-ratio--all-customer">
                    <div className="o-ratio__content">
                      <Image data={rs.image} />
                      <div className="absolute bottom-0 left-0 p-4">
                        <div className="px-5 py-3 bg-black">
                          <Image data={rs.logo} className="max-w-full max-h-full" style={{ maxHeight: '25px', maxWidth: '85px' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4">{rs.content}</p>
                </Link>
              ))}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query Customers {
    wpPage(slug: { eq: "customers" }) {
      id
      title
      slug
      ...SEO
      customerListing {
        customerHero {
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
          testimonials {
            content
            logo {
              localFile {
                publicURL
              }
            }
          }
        }
        customerLogos {
          heading
          logos {
            logo {
              ...Image
            }
          }
        }
        featuredCustomers {
          image {
            ...Image
          }
          logo {
            ...Image
          }
          stats {
            stat
            statDescription
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
          hasTestimonial
          testimonialWrapper {
            testimonial {
              backgroundColour
              quote
              cite {
                name
                role
              }
            }
          }
        }
        testimonial {
          backgroundColour
          quote
          cite {
            name
            role
          }
        }
        moreCustomers {
          content
          link
          image {
            ...Image
          }
          logo {
            ...Image
          }
        }
      }
    }
  }
`;
