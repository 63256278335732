import React from 'react'
import custBlock from '../assets/images/blocks/cust-test-block.svg'
import styled from 'styled-components';

const TestBlock = styled.div`
     filter: drop-shadow(6px 6px 0px rgba(0, 0, 0, 0.08));

    .bg-image{
      top: 5%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index:-1
    }
`;

function CustomerHeroTestimonials({className = "", content, logo, logoClass = ""}) {
    return (
      <TestBlock className={`w-full md:block sm:max-w-md md:max-w-lg ${className}`}>
        <div className="relative py-10">
          <div className="flex flex-col justify-center w-full pl-5 pr-8 md:px-8 lg:pl-6 lg:pr-10 ">
            <div className="mb-4 text-card__content md:ml-0 customers-text-block md:max-w-none lg:max-w-23 xl:max-w-none" dangerouslySetInnerHTML={{ __html: content }} />
            <img src={logo?.localFile.publicURL} alt="" className={`mr-auto w-1/3 ${logoClass} `} />
          </div>
          <img src={custBlock} class="absolute w-full h-full bg-image" />
        </div>

      </TestBlock>
    )
}

export default CustomerHeroTestimonials
